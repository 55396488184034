import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCUJr9EpO3F2sI-y7s9s5Wgz3-NPlAp0qQ",
  authDomain: "blackfin-feedback-widget.firebaseapp.com",
  projectId: "blackfin-feedback-widget",
  storageBucket: "blackfin-feedback-widget.appspot.com",
  messagingSenderId: "1022204831633",
  appId: "1:1022204831633:web:1d78173b5d6b8ef8045c30"
};

const app = initializeApp(firebaseConfig);

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(app);
const sendFeedBackFunction = httpsCallable(functions, 'sendFeedback');

export const sendFeedback = async(data) => {
  
  //return true;

  try {
    const send = await sendFeedBackFunction(data);
    return true;
  } catch(error) {
    return false;
  }

};