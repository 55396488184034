import {h, render, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks'
import styles from "./styles.css";
import { sendFeedback } from './utils/firebase/firebase.utils';
import successIcon from './success-icon.png';
import errorIcon from './close.png';
import { isEmail } from "./utils/string";

window.FeedbackWidget = {
  user_custom_data: null,
  setUserCustomData: function (data) {
    this.user_custom_data = data;
  },
};

const defaultFormFields = {
  comment: "",
  email: "",
};

const Container = () => {
  const { platform, userAgent } = navigator;

  const [formVisible, setFormVisible] = useState(false);

  const [showResponse, setShowResponse] = useState(false);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [fieldsError, setFieldsError] = useState({
    email: "",
    comment,
  });

  const { comment, email } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });

    if (name === "email") {
      if (!value) {
        setFieldsError((prev) => ({ ...prev, email: "Email cannot be empty" }));
      } else if (!isEmail(value)) {
        setFieldsError((prev) => ({ ...prev, email: "This email not valid" }));
      } else {
        setFieldsError((prev) => ({ ...prev, email: "" }));
      }
    }
  };

  const buttonCLick = (event) => {
    setFormVisible(true);
  };

  const closeButtonClick = (event) => {
    setFormVisible(false);
    setShowResponse(false);
    setSent(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSending(true);

    const dataToPass = {
      url: window.location.href,
      platform: platform,
      userAgent: userAgent,
      screenWidth: screen.width,
      screenHeight: screen.height,
      comment: comment,
      email: email,
      user_custom_data: window.FeedbackWidget?.user_custom_data,
    };

    const send = await sendFeedback(dataToPass);

    setSent(send);
    setShowResponse(true);
    setSending(false);
  };

  return (
    <div class="inner-container">
      {!formVisible && (
        <div className="feedback-button-container">
          <button
            className="feedback-button animate__backInUp"
            onClick={buttonCLick}
          >
            Feedback
          </button>
        </div>
      )}

      {formVisible && (
        <Fragment>
          <div className="feedback-form-container animate__backInRight">
            <span className="close-button-container" onClick={closeButtonClick}>
              <svg
                class="icon-0-2-15"
                viewBox="0 0 22 22"
                style="fill: currentcolor;"
              >
                <path
                  d="M1 1l10 10L21 1M1 21l10-10 10 10"
                  fill="none"
                  fill-rule="evenodd"
                  stroke="currentColor"
                  stroke-width="2"
                ></path>
              </svg>
            </span>

            {!showResponse && <h1>Feedback</h1>}

            <form className={`feedback-form`} onSubmit={handleSubmit}>
              {!showResponse && (
                <div className={`form-group-container`}>
                  <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <textarea
                      required
                      placeholder="Your Feedback"
                      name="comment"
                      id="comment"
                      cols="10"
                      rows="10"
                      onInput={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Your Email</label>
                    <input
                      required
                      placeholder="someone@something.com"
                      type="email"
                      name="email"
                      id="email"
                      onInput={handleChange}
                    />
                    <span className="form-error">{fieldsError.email}</span>
                  </div>
                </div>
              )}

              {showResponse && (
                <div className="response-container">
                  {sent && (
                    <div className="success-message">
                      <img src={successIcon} width="50" alt={`Success`} />
                      <h4>Thank You!</h4>
                      <p>
                        We really appreciate your <br /> feedback.
                      </p>
                    </div>
                  )}

                  {!sent && (
                    <div className="error-message">
                      <img src={errorIcon} width="50" alt={`Success`} />
                      <h4>Error!</h4>
                      <p>Sending feedback failed.</p>
                    </div>
                  )}
                </div>
              )}

              {!showResponse && (
                <Fragment>
                  <button
                    disabled={
                      sending || fieldsError.email || fieldsError.comment
                    }
                    type="submit"
                    className="feedback-form-btn"
                  >{`${sending ? "Sending" : "Submit"}`}</button>
                  <div className="form-bottom-cover"></div>
                </Fragment>
              )}
            </form>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const appRoot = document.querySelector("#feedback-widget-container");
appRoot.attachShadow({
  mode: "open",
});

const styleTag = document.createElement("style");
styleTag.innerHTML = styles;
appRoot.shadowRoot.appendChild(styleTag);

render(<Container />, appRoot.shadowRoot);